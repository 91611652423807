import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "managing-pressures-and-expectations"
    }}>{`Managing pressures and expectations`}</h1>
    <p>{`Teenagers often feel pressure to live up to their own expectations of themselves as well as expectations from family, friends, school, society and the media. For example, there may be expectations that they be brainy, athletic, nurturing, pretty/muscular whilst excelling at school, being popular, joining in extra-curricular activities and getting selected for the top sporting teams.`}</p>
    <p>{`As a parent, you can help your teenager manage these pressures by:`}</p>
    <h3 {...{
      "id": "encouraging-your-teenager-to-set-realistic-goals-for-themselves-and-to-follow-through-with-their-goals"
    }}>{`Encouraging your teenager to set realistic goals for themselves, and to follow through with their goals.`}</h3>
    <p>{`Help your teenager to think of different ways they can achieve their goals, and to select the best one. Encourage them to persist with the unpleasant, boring tasks that are necessary for achieving their goals. You can set an example for your teenager by persisting and not procrastinating in working towards your own goals, even when you are feeling discouraged.`}</p>
    <h3 {...{
      "id": "discussing-what-social-pressures-your-teenager-feels-particularly-the-messages-they-receive-from-the-media"
    }}>{`Discussing what social pressures your teenager feels, particularly the messages they receive from the media.`}</h3>
    <p>{`Talk with them about how these messages may be shaping their values, perceptions and expectations about life (see the `}<GoTo to="/dashboard" mdxType="GoTo"><i>{`Connect`}</i></GoTo>{` module for strategies on talking with your teenager).`}</p>
    <h3 {...{
      "id": "monitoring-how-much-pressure-you-may-be-placing-on-your-teenager"
    }}>{`Monitoring how much pressure you may be placing on your teenager.`}</h3>
    <p>{`Although it is important for parents to have positive expectations for their teenager and their future, excessive parental pressure to live up to these expectations can increase the teenager’s risk of developing depression and clinical anxiety. Try to match your expectations for your teenager to their individual personality and capabilities.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m8/40.svg" alt="Sad Teen" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      